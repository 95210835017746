import { Icons } from "@pankod/refine-antd";
import { DashboardPage } from "pages/dashboard";
import { Definitions } from "pages/definitions";
import {
  PatientCreate,
  PatientEdit,
  PatientList,
  PatientShow,
} from "pages/patients";
import { UserManagement } from "pages/userManagement";

const patients = {
  name: "patients",
  list: PatientList,
  create: PatientCreate,
  edit: PatientEdit,
  show: PatientShow,
  icon: <Icons.MedicineBoxOutlined />,
};


export const resources = (user: any) => {
  const groups =
    user?.signInUserSession?.accessToken?.payload?.["cognito:groups"];
  const isSuperAdmin = groups?.includes?.("superadmin");
  const isAdmin = groups?.includes?.("admin");

  const list: any = [];

  if (isAdmin) {
    list.push({
      name: "dashboard",
      list: DashboardPage,
      icon: <Icons.DashboardOutlined />,
    });
  }

  if (isSuperAdmin) {
    list.push(
      {
        name: "users",
        list: UserManagement,
        icon: <Icons.TeamOutlined />,
        options:{
          label:"User Management"
        }
      },
      {
        name: "definitions",
        list: Definitions,
        icon: <Icons.FileTextOutlined />,
      }
    );
  }

  // if isDoctor or isNurse
  list.push(patients);

  return {list, isAdmin, groups};
};
