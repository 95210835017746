import { useRef } from "react";
import { Select, Input } from "@pankod/refine-antd";

import countries2 from "assets/data/countries2.json";

import { IPhoneNumber } from "./types";

import "./style.less";

const { Option } = Select;

interface Props {
  value?: IPhoneNumber;
  onChange?: any;
}

const CLASS = "hurone-phone-input";

const PhoneNumber: React.FC<Props> = ({ value, onChange }) => {
  const phoneInputRef = useRef<any>();

  const handleDialCode = (dialCode: string) => {
    onChange({ ...value, dialCode });
    if (!value?.number) phoneInputRef.current.focus();
  };

  const handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredValue = event.target.value.replace(/\D/g, "");
    onChange({ ...value, number: filteredValue });
  };

  const getCountryCode = (dialCode: string) => {
    const country = countries2.find((country) => country.dialCode === dialCode);

    return country?.code || "";
  };

  return (
    <Input.Group compact>
      <Select
        // className={`${CLASS}_country`}
        value={value?.dialCode}
        onChange={handleDialCode}
        showSearch
        filterOption={(input, option: any) =>
          option.value.indexOf(input) >= 0 ||
          getCountryCode(option.value)
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        style={{
          width: "40%",
        }}
      >
        {countries2.map((country: any, index) => (
          <Option key={index} value={country.dialCode}>
            <span className={`${CLASS}_country_option`}>
              {country.dialCode}
              <span style={{marginRight:8}}>
                {country.emoji}
              </span>
            </span>
          </Option>
        ))}
      </Select>
      <Input
        className={`${CLASS}_number`}
        value={value?.number}
        onChange={handlePhoneNumber}
        maxLength={14}
        ref={phoneInputRef}
        style={{
          width: "60%",
        }}
      />
    </Input.Group>
  );
};

export default PhoneNumber;
