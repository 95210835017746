import React, { FC, useContext, useEffect, useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  Create,
  Form,
  Input,
  Select,
  Button,
  SaveButton,
  useSelect,
  useStepsForm,
  Steps,
  Icons,
  DatePicker,
  Row,
  Col,
  Divider,
  Typography,
  InputNumber,
  FormInstance,
} from "@pankod/refine-antd";
import Selects from "components/selects";
import DateOfBirth from "components/birthDate";
import { FamilyHistory, validateFamilyHistory } from "components/FamilyHistory";
import { tagRender, maxTagPlaceholder } from "utils/tagRender/tagRender";
import { calcBMI, calcBSA } from "utils/calculations";

interface IMedicalHistoryProps {
  patientID?: string;
  isFullScreen?: boolean;
  form?: FormInstance<any>;
}

const { InfoCircleOutlined } = Icons;

const { Text } = Typography;

export const MedicalHistoryForm: FC<IMedicalHistoryProps> = (props) => {
  const { patientID, isFullScreen, form } = props;

  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");



  useEffect(() => {
    let h = form?.getFieldValue("height");
    let w = form?.getFieldValue("weight");



 

    if (w === "" || h === "") {
      form?.setFieldsValue({
        bsa: "",
        bmi: "",
      });

      return;
    }

    if (h?.length > 1 && w?.length > 1) {
      form?.setFieldsValue({
        bsa: calcBSA(Number(h), Number(w)),
        bmi: calcBMI(Number(h), Number(w)),
      });
    }

       let errors = form?.getFieldsError(["height", "weight"]);

    const names = errors?.flatMap?.((e: any) => {
      if (e.errors.length === 0) {
        return e.name;
      } else return [];
    });

    //  form?.setFieldsValue({
    //   bsa: calcBSA(Number(h), Number(w)),
    //   bmi: calcBMI(Number(h), Number(w)),
    // });

    // form
    //   ?.validateFields()
    //   .then((val: any) => {
    //     // const h = val.height;
    //     // const w = val.weight;
    //     // form?.setFieldsValue({
    //     //   bsa: calcBSA(Number(h), Number(w)),
    //     //   bmi: calcBMI(Number(h), Number(w)),
    //     // });
    //   })
    //   .catch((err) => {
    //     const names = err.errorFields.flatMap((e: any) => e.name);
    //     if (names && !names.includes("height" && "weight")) {
    //       form?.setFieldsValue({
    //         bsa: calcBSA(Number(h), Number(w)),
    //         bmi: calcBMI(Number(h), Number(w)),
    //       });
    //     }
    //   });
  }, [weight, height, form]);

  return (
    <>
      <Divider orientation="left">
        {<Text type="secondary">Complaints</Text>}
      </Divider>
      <Row gutter={16}>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Primary Complaints: Breast"
            name="primaryComplaintsBreast"
            rules={
              [
                // {
                //   required: true,
                // },
              ]
            }
          >
            <Selects
              datakey="primaryComplaintsBreast"
              mode="multiple"
              tagRender={tagRender}
              maxTagCount={3}
              maxTagPlaceholder={maxTagPlaceholder}
              maxTagTextLength={9}
            />
          </Form.Item>
        </Col>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Primary Complaints: Other"
            name="primaryComplaintsOther"
            rules={
              [
                // {
                //   required: true,
                // },
              ]
            }
          >
            <Selects
              datakey="primaryComplaintsOther"
              mode="multiple"
              tagRender={tagRender}
              maxTagCount={3}
              maxTagPlaceholder={maxTagPlaceholder}
              maxTagTextLength={9}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item label="Other Complaints" name="otherComplaints">
            <Input />
          </Form.Item>
        </Col>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Had Symptoms since"
            name="hadSymptomsSince"
            rules={[
              {
                required: true,
                message: "Had Symptoms since field is required.",
              },
            ]}
          >
            <DateOfBirth disableFutureDates={true} decreaseYear={0} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="First Sought Advice/Treatment"
            name="firstAdviceOrTreatment"
            rules={[
              {
                required: true,
                message: "First Sought Advice/Treatment field is required.",
              },
            ]}
          >
            <Selects datakey="firstAdviceOrTreatment" />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left">
        {<Text type="secondary">Body measures</Text>}
      </Divider>

      <Row gutter={16}>
        <Col span={isFullScreen ? 3 : 6}>
          <Form.Item
            label="Height (cm)"
            name="height"
            rules={[
              {
                pattern: /^\d+$/,
                message: "Please use only digits",
              },
              {
                pattern: /^($|[^0])/,
                message: "Can't start with 09",
              },
              {
                min: 2,
                message: "At least two digits",
              },
            ]}
          >
            <Input maxLength={3} onChange={(e) => setHeight(e.target.value)} />
          </Form.Item>
        </Col>

        <Col span={isFullScreen ? 3 : 6}>
          <Form.Item
            label="Weight (kg)"
            name="weight"
            rules={[
              {
                pattern: /^\d+$/,
                message: "Please use only digits",
              },
              {
                pattern: /^($|[^0])/,
                message: "Can't start with 0",
              },
              {
                min: 2,
                message: "At least two digits",
              },
            ]}
          >
            <Input maxLength={3} onChange={(e) => setWeight(e.target.value)} />
          </Form.Item>
        </Col>

        <Col span={isFullScreen ? 3 : 6}>
          <Form.Item
            name="bsa"
            label="BSA (m²)"
            tooltip={{
              title: "Body Surface Area",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input
              maxLength={7}
              disabled
              style={{ backgroundColor: "#fff", color: "#626262" }}
            />
          </Form.Item>
        </Col>
        <Col span={isFullScreen ? 3 : 6}>
          <Form.Item
            name="bmi"
            label="BMI (kg/m²)"
            tooltip={{
              title: "Body Mass Index",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input
              maxLength={7}
              disabled
              style={{ backgroundColor: "#fff", color: "#626262" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left">
        {<Text type="secondary">Medical History</Text>}
      </Divider>
      <Row gutter={16}>
        <Col span={isFullScreen ? 5 : 10}>
          <Form.Item dependencies={["gender"]}>
            {({ getFieldValue }) => {
              const isMale = getFieldValue("gender") === 1;
              return (
                <Form.Item
                  label="Age at first menstrual period"
                  name="firstMenstrualPeriod"
                  initialValue={null}
                  rules={[
                    {
                      pattern: /^\d+$/,
                      message: "Must be a number.",
                    },
                    {
                      required: !isMale,
                      message:
                        "Age at first menstrual period field is required.",
                    },
                  ]}
                >
                  <InputNumber min={1} max={99} disabled={isMale} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={isFullScreen ? 7 : 14}>
          <Form.Item
            label="Past Medical history"
            name="pastMedicalHistory"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
          >
            <Selects
              datakey="pastMedicalHistory"
              mode="multiple"
              tagRender={tagRender}
              maxTagCount={3}
              maxTagPlaceholder={maxTagPlaceholder}
              maxTagTextLength={9}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={isFullScreen ? 12 : 24}>
          <Form.Item
            label="Family History"
            name="familyHistory"
            validateTrigger="onSubmit"
            rules={[...validateFamilyHistory]}
          >
            <FamilyHistory />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left">
        {<Text type="secondary">Diagnosis</Text>}
      </Divider>
      <Row gutter={16}>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Cancer Stage"
            name="cancerStage"
            rules={[
              {
                required: true,
                message: "Cancer Stage field is required.",
              },
            ]}
          >
            <Selects datakey="cancerStage" />
          </Form.Item>
        </Col>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Histology"
            name="histology"
            rules={[
              {
                required: true,
                message: "Histology field is required.",
              },
            ]}
          >
            <Selects datakey="histology" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Biological subtype"
            name="biologicalSubtype"
            rules={[
              {
                required: true,
                message: "Biological subtype field is required.",
              },
            ]}
          >
            <Selects datakey="biologicalSubtype" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="" name="id" initialValue={patientID} hidden>
        <Input />
      </Form.Item>
    </>
  );
};
