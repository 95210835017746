import React from "react";
import { TitleProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/">
    {collapsed ? (
     <img
     src={'/images/logo/huroneLogo.png'}
     alt="HuroneAI"
     style={{
       width: '200px',
       padding: '32px',
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
       float: 'left',
     }}
   />
    ) : (
      <div
      // src={'/images/logo/huroneLogo.png'}
      // alt="HuroneAI"
      style={{
        height: '64px',
        width: '160px',
        backgroundColor: "transparent",
        // zIndex: -1,
    //  padding: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // float: 'left',
      }}
    >
        <img
          src={'/images/logo/huroneLogo.png'}
          alt="HuroneAI"
          style={{
            width: '140px',
             padding: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            float: 'left',
            zIndex: 1000
          }}
        />
    </div>
    )}
  </Link>
);
