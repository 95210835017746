import get from 'lodash.get';
import jsonData from 'assets/data/patientData.json';

const patientsData: any = jsonData;

const matchValue = (value: number, key: string): string => {
  const matchedValue = patientsData?.[key]?.find((item: any) => item.value === value)?.label;
  return matchedValue;
};

const matchArray = (array: number[], key: string | any[]): string => {
  const matchedValues = array
    ?.map((val: any) => get(patientsData, key).find((item: any) => item.value === val)?.label)
    .join(', ');

  return matchedValues;
};

export { matchValue, matchArray };