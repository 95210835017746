import { useState } from "react";
import { Checkbox, Select } from "@pankod/refine-antd";

import type { CheckboxChangeEvent } from "antd/es/checkbox";

import { tagRender, maxTagPlaceholder } from "utils/tagRender/tagRender";

import "./ConditionalSelect.less";

interface Props {
  value?: any;
  onChange?: any;
  onUncheck?: any;
  label?: string;
  options: any;
  dataKey: any;
}

const CLASS = "hurone-conditional-select";

const ConditionalSelect: React.FC<Props> = ({
  value,
  onChange,
  onUncheck,
  label,
  options,
  dataKey,
}) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(value?.length);
  const [selectedValue, setSelectedValue] = useState<number[]>(value || []);

  const handleCheck = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;

    if (!checked) {
      onUncheck(dataKey);
      setSelectedValue([]);
    } else {
      onChange(null, dataKey);
    }
    setIsEnabled(checked);
  };

  const handleSelect = (e: number[]) => {
    setSelectedValue(e);
    onChange(e, dataKey);
  };

  return (
    <div className={CLASS}>
      <div className={`${CLASS}_checkbox`}>
        <Checkbox onChange={handleCheck} defaultChecked={value?.length}>
          {label}
        </Checkbox>
      </div>
      <div className={`${CLASS}_select`}>
        <Select
          value={selectedValue}
          onChange={handleSelect}
          disabled={!isEnabled}
          placeholder="Please select"
          mode="multiple"
          showArrow
          tagRender={tagRender}
          maxTagCount={2}
          maxTagPlaceholder={maxTagPlaceholder}
          maxTagTextLength={7}
          options={options}
        />
      </div>
    </div>
  );
};

export default ConditionalSelect;
