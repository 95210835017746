import { Refine } from "@pankod/refine-core";
import { Authenticator } from "@aws-amplify/ui-react";
import routerProvider from "@pankod/refine-react-router-v6";
import { Title, Header, Layout, Sider } from "components/layout";
import amplifyDataProvider from "providers/dataProviderRest";
import { components } from "components/login";
import { createContext } from "react";
import { resources } from "resources";
import { ErrorResult } from "components/error";
import Analytics from '@aws-amplify/analytics';

import "styles/antd.less";

export const UserDataContext = createContext<any>({});

function App() {
  return (
    <Authenticator components={components} hideSignUp>
      {({ signOut, user }) => {
        const { list, isAdmin, groups } = resources(user);
        return (
          <UserDataContext.Provider value={user}>
            <Refine
              options={{ warnWhenUnsavedChanges: true, disableTelemetry: true }}
              routerProvider={routerProvider}
              dataProvider={amplifyDataProvider()}
              catchAll={<ErrorResult />}
              resources={list}
              Title={Title}
              Header={() => <Header user={user} signout={signOut} />}
              Sider={() => (isAdmin ? <Sider groups={groups} /> : <></>)}
              Layout={Layout}
            />
          </UserDataContext.Provider>
        );
      }}
    </Authenticator>
  );
}

export default App;
