import React, { useEffect } from "react";
import { IResourceComponentsProps, useNavigation } from "@pankod/refine-core";

import {
  Create,
  Form,
  Input,
  Select,
  Button,
  SaveButton,
  useSelect,
  useStepsForm,
  Steps,
  Icons,
  Space,
  Row,
  Col,
} from "@pankod/refine-antd";

import { IPost, ICategory } from "interfaces";
import Selects from "components/selects";
import { BasicInformation } from "./forms/basicInformation";
import { MedicalHistoryForm } from "./forms/medicalHistory";
import { TreatmentPlanForm } from "./forms/treatmentPlan";

const { Step } = Steps;

export const PatientCreate: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const { current, gotoStep, stepsProps, formProps, saveButtonProps, form } =
    useStepsForm({
      redirect: "show",
      onMutationSuccess: () => console.log("patient created successfully")
    });

  const formList = [
    <BasicInformation isFullScreen />,
    <MedicalHistoryForm isFullScreen form={form} />,
    // <TreatmentPlanForm />,
  ];

  return (
    <Create
      //   wrapperProps={{

      // }}
      breadcrumb={false}
      // footerButtonProps={{styles: "width"}}
      headerButtons={null}
      footerButtons={
        <div style={{ display: "flex", float: "left" }}>
          {current > 0 && (
            <Button
              style={{ width: "140px" }}
              onClick={() => {
                gotoStep(current - 1);
              }}
            >
              Previous
            </Button>
          )}
          {current < formList.length - 1 && (
            <Button
              style={{ width: "140px" }}
              onClick={() => {
                gotoStep(current + 1);
              }}
            >
              Next
            </Button>
          )}
          {current === formList.length - 1 && (
            <SaveButton {...saveButtonProps} style={{ width: "140px" }} />
          )}
        </div>
      }
    >
      <Steps {...stepsProps} type="navigation" style={{ width: "50%" }}>
        <Step title="Basic Information" icon={<></>} />
        <Step title="Medical History" icon={<></>} />
      </Steps>

      <Form {...formProps} layout="vertical" style={{ marginTop: 30 }}>
        {formList[current]}
      </Form>
    </Create>
  );
};
