import dayjs from "dayjs";
import adaptSurveyData from "./adaptSurveyData";
const findTreatmentStatus = (patientID: string, treatments: any[]): any => {
    if (treatments) {
        const currentTreatmentPlan = treatments
            .filter?.((treatment: any) => treatment.patientID === patientID)
            .sort?.((a: any, b: any) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())?.[0];

        // if (!currentTreatmentPlan?.status) {
        if (!currentTreatmentPlan) {
            return 'Not Configured';
        }

        // else if (!currentTreatmentPlan?.isPatientCompliant) {
        //     return 'Non - compliant';
        // } else if (currentTreatmentPlan?.isPatientCompliant) {
        //     return currentTreatmentPlan?.status 
        // } 

        else {
            return currentTreatmentPlan?.status || 'Not Configured';
        }
        // }
    }
}

export const findLastSurvey = (patientID: string, surveys: any[]): any => {
    if (surveys) {
        const lastSurvey = surveys.filter?.((survey: any) => survey.patientID === patientID)
    //    .filter?.((survey: any) => survey.answers.length > 0)
        // .filter?.((survey: any) => survey.questions.length > 0)
         .sort?.((a: any, b: any) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())?.[0];

        if (!lastSurvey) return;
        const { questions, answers } = lastSurvey

        const surveysData = adaptSurveyData(questions, answers)

        return surveysData

    }
}
const joinPatientsWithTreatments = (patients: any[], treatments: any[], surveys: any[]): any[] => {
    const joined = patients?.map?.(patient => ({
        ...patient, treatmentStatus: findTreatmentStatus(patient.id, treatments),
        survey: findLastSurvey(patient.id, surveys)
    }))

    return joined;
};
export { joinPatientsWithTreatments }