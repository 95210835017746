import React, { FC, useContext, useState } from "react";

import { Form, Input, DatePicker, Row, Col } from "@pankod/refine-antd";
import Selects from "components/selects";
import { UserDataContext } from "App";
import dayjs from "dayjs";
import { getRange } from "utils/calculations";

interface IAppointmentsFormProps {
  patientID?: string;
}
const disabledDate = (current: any) => {
  return current && current <= dayjs().startOf("day");
};

const disabledDateTime = () => ({
  disabledHours: () => getRange(0, 24).filter((hour) => hour < dayjs().hour()),
  disabledMinutes: () =>
    getRange(0, 60).filter((minute) => minute < dayjs().minute() + 1),
});
export const AppointmentsForm: FC<IAppointmentsFormProps> = (props) => {
  const { patientID } = props;
  const user = useContext(UserDataContext);
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Location"
            name="appointmentLocation"
            rules={[
              {
                required: true,
                message: "Loacation is required field.",
              },
            ]}
          >
            <Input maxLength={50} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="Date"
            name="appointmentDate"
            rules={[
              {
                required: true,
                message: "Appointment date is required field.",
              },
            ]}
          >
            <DatePicker
              showTime={{ format: "HH:mm" }}
              disabledDate={disabledDate}
              disabledTime={disabledDateTime}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Reason"
            name="appointmentReason"
            rules={[
              {
                required: true,
                message: "Appointment reason is required field.",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Treatment Status" name="treatmentStatus">
            <Selects datakey="treatmentStatus" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="" name="patientID" initialValue={patientID} hidden>
        <Input maxLength={50} />
      </Form.Item>

      <Form.Item label="" name="createdBy" initialValue={user?.username} hidden>
        <Input maxLength={50} />
      </Form.Item>
    </>
  );
};
