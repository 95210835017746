import {
  AntdLayout,
  Space,
  Menu,
  Icons,
  Dropdown,
  Avatar,
  Typography,
  Modal,
  Form,
  useModal,
  message,
} from "@pankod/refine-antd";
import { useCreate } from "@pankod/refine-core";
import { SupportForm } from "pages/patients/forms/support";
import { FC } from "react";

const { DownOutlined, UserOutlined, LogoutOutlined, CustomerServiceOutlined } =
  Icons;
const { Text } = Typography;

const items = [
  {
    label: "Support",
    key: "support",
    icon: <CustomerServiceOutlined />,
  },
  { label: "", key: "divider", type: "divider" },
  { label: "Logout", key: "logout", icon: <LogoutOutlined /> },
];

interface IHeaderProps {
  user: any;
  signout: any;
}

export const Header: FC<IHeaderProps> = (props) => {
  const { user, signout } = props;
  const { modalProps, show, close } = useModal();
  const { mutate, isLoading } = useCreate();
  const [form] = Form.useForm();

  const { attributes, username } = user;
  const { email } = attributes;

  const onMenuItemClick = (event: any) => {
    const { key } = event;
    console.log(key);
    if (key === "logout") {
      signout({ global: true });
    }
    if (key === "support") {
      show();
      console.log(username);
    }
  };

  const sendMessage = () => {
    form.validateFields().then((val) => {
      mutate(
        {
          resource: "support",
          values: { ...val, username: username },
        },
        {
          onSuccess: () => {
            form.resetFields();
            message.success("The message was sent successfully");
            close();
          },
          onError: () => {
            form.resetFields();
            message.error("An error has occurred");
            close();
          },
        }
      );
    });
  };
  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        width: "100%",
        height: "64px",
        top: 0,
        backgroundColor: "#FBFBFB",
        position: "fixed",
        zIndex: 1000,
        // overflow: 'hidden',
      }}
    >
      <div style={{ width: "100%" }}>
        <img
          src={"/images/logo/huroneLogo.png"}
          alt="HuroneAI"
          style={{
            width: "116px",
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            float: "left",
            zIndex: 1000,
          }}
        />
      </div>
      <Dropdown overlay={<Menu items={items} onClick={onMenuItemClick} />}>
        <Space style={{ marginLeft: "8px" }}>
          <Avatar>
            <UserOutlined onClick={() => console.log(user)} />
          </Avatar>
          <Text ellipsis strong>
            {user.attributes.email}
          </Text>
          <DownOutlined />
        </Space>
      </Dropdown>

      <Modal
        {...modalProps}
        title="Support"
        okText="Send Message"
        onOk={sendMessage}
        okButtonProps={{ loading: isLoading }}
      >
        <Form initialValues={{ email }} layout="vertical" form={form}>
          <SupportForm />
        </Form>
      </Modal>
    </AntdLayout.Header>
  );
};
