const handleCarretPosition = (inputRef: any) => {
  const getCaretPosition = () => {
    return inputRef.current.resizableTextArea.textArea.selectionStart;
  };

  const setCaretPosition = (caretPos: number) => {
    inputRef.current.resizableTextArea.textArea.focus();
    inputRef.current.resizableTextArea.textArea.setSelectionRange(caretPos, caretPos);
  };

  return { getCaretPosition, setCaretPosition }
}

export default handleCarretPosition;