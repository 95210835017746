import {
  Typography,
  Tag,
  Icons,
  Card,
  Space,
  Button,
  Divider,
  Descriptions,
} from '@pankod/refine-antd';
import './style.less';

import CardItem from 'components/cardItem';
import { AppointmentsTabPane } from '../appointments';

const { PlusOutlined, SolutionOutlined } = Icons;

const AppointmentsCard = (props: any) => {
  const { onAddNew, onHistory, record, patientID } = props;

  return (
    <Card
      className="card-shadow appointments-card"
      headStyle={{ borderBottom: 'none' }}
      bodyStyle={{ padding: 0 }}
      title={<h3 className="card-title">Latest Appointments</h3>}
      extra={
        <Space>
          <Button type="link" icon={<SolutionOutlined />} onClick={onHistory}>
            History
          </Button>
          <Button type="primary" icon={<PlusOutlined />} onClick={onAddNew}>
            Add New
          </Button>
        </Space>
      }
      style={{ backgroundColor: '#fff' }}
    >
      <AppointmentsTabPane
        patientID={patientID}
        title={false}
        addNewButton={false}
        pagination={false}
        initialPageSize={3}
        style={{ backgroundColor: '#fff' }}
      />
    </Card>
  );
};

export default AppointmentsCard;
