import { Button, Result } from "@pankod/refine-antd";
import { Link } from "react-router-dom";

export const ErrorResult: React.FC = () => (
  <Result
    //   status="404"
    title="oops"
    subTitle="Sorry, the page you visited does not exist or you're not authorized."
    extra={
      <Link to="/">
        <Button type="primary">Back Home</Button>
      </Link>
    }
  />
);
