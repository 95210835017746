export const components = {
    Header() {
      return (
        <div style={{ textAlign: "center" }}>
          <img
            src={"/images/logo/huroneLogo.png"}
            alt="HuroneAI"
            style={{
              width: "250px",
              padding: "32px",
            }}
          />
        </div>
      );
    },
  };