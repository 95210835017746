import { ElementType, FC } from "react";
import { Space } from "@pankod/refine-antd";
import isPlainObject from "lodash.isplainobject";

import { matchValue, matchArray } from "utils/getPatientsData";

interface ICardItemProps {
  Icon?: ElementType;
  label?: string;
  val?: any;
  datakey?: string;
  datakeyNested?: string;
  valkey?: number | number[] | object;
  spacewidth?: string;
  spanwidth?: string;
}

const CardItem: FC<ICardItemProps> = (props) => {
  const {
    Icon,
    label,
    val,
    valkey,
    datakey,
    datakeyNested,
    spacewidth,
    spanwidth,
  } = props;

  const spanStyle: any = {
    fontSize: "14px",
    // wordWrap: 'break-word',
    width: spanwidth || "140px",
    display: "inline-block",
    textOverflow: "elipsis",

    //  whiteSpace: "nowrap"
  };

  return (
    <Space size={12} style={{ minWidth: spacewidth || "176px" }}>
      {Icon && <Icon style={{ fontSize: "20px", color: "#9F9DA3" }} />}
      <Space direction="vertical" size={0}>
        {val && <span style={spanStyle}>{val}</span>}

        {((valkey && typeof valkey === "number") || valkey === 0) &&
          datakey && (
            <span style={{ ...spanStyle, textOverflow: "ellipsis" }}>
              {matchValue(valkey, datakey)}
            </span>
          )}

        {Array.isArray(valkey) && datakey && (
          <span style={{ fontSize: "13px" }}>
            {matchArray(valkey, datakey)}
          </span>
        )}

        {isPlainObject(valkey) && valkey && datakey && datakeyNested && (
          <span style={{ fontSize: "13px" }}>
            {Object.keys(valkey).map((key, index, array) => (
              <span key={`${key}${index}`}>
                {matchValue(+key, datakey) +
                  `(${matchArray(valkey?.[key as keyof {}], [
                    "familyHistory",
                    key,
                    datakeyNested,
                  ])})` +
                  `${index + 1 !== array.length ? ", " : ""}`}
                <br></br>
              </span>
            ))}
          </span>
        )}

        {/* {(!val && val !== 0) && (!datakey && !valkey) && <span>/</span>} */}

        <span style={{ fontSize: "12px", color: "#9F9DA3" }}>{label}</span>
      </Space>
    </Space>
  );
};
export default CardItem;
