import { Auth, API } from 'aws-amplify';

async function addToGroup() {
  let apiName = 'AdminQueries';
  let path = '/addUserToGroup';
  let myInit = {
    body: {
      "username": "richard",
      "groupname": "Editors"
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, myInit);
}

let nextToken: any;

export async function listEditors() {
  let apiName = 'AdminQueries';
  let path = '/listUsersInGroup';
  let myInit = {
    queryStringParameters: {
      "groupname": "doctors",
      "limit": 50,
      "token": nextToken
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  const { NextToken, ...rest } = await API.get(apiName, path, myInit);
  nextToken = NextToken;

  return rest;
}