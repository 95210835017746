import React from 'react';
import { Column } from '@ant-design/plots';

const Column1:any = Column

interface ColumnChartProps {
    data: any
}

const ColumnChart: React.FC<ColumnChartProps> = (props) => {
    const {data} = props
 
  const config = {
    data,
    xField: 'type',
    yField: 'sales',
    label: {
      position: 'middle',
      // 'top', 'bottom', 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: 'Weeks',
      },
      sales: {
        alias: 'Cycle Numbers',
      },
    },
  };
  return <Column1 {...config} />;
};

export default ColumnChart


