import {
  ButtonProps,
  Create,
  Drawer,
  DrawerProps,
  Edit,
  Form,
  FormProps,
  RefreshButton,
} from "@pankod/refine-antd";
import { useCreate, useUpdate } from "@pankod/refine-core";
import { UserDataContext } from "App";
import { useContext } from "react";
import { AppointmentsForm } from "../forms/appointments";
import { BasicInformation } from "../forms/basicInformation";
import { MedicalHistoryForm } from "../forms/medicalHistory";
import { TreatmentPlanForm } from "../forms/treatmentPlan";

interface IDrawerFormProps {
  selectedForm:
    | "Basic Information"
    | "Medical History"
    | "Treatment Plan"
    | "New Treatment Plan"
    | "New Appointment";
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  patientID?: any;
  record?: any;
  close?: any;
  treatmentLength?: number;
}
export const DrawerForm: React.FC<IDrawerFormProps> = (props) => {
  const {
    selectedForm,
    drawerProps,
    formProps,
    saveButtonProps,
    record,
    close,
    patientID,
    treatmentLength,
  } = props;

  const user = useContext(UserDataContext);

  const [editForm] = Form.useForm();
  const [createTreatmentForm] = Form.useForm();
  const { mutate } = useUpdate();
  const { mutate: create } = useCreate();

  return (
    <Drawer
      {...drawerProps}
      destroyOnClose
      forceRender={false}
      zIndex={1001}
      title={
        <strong>
          {selectedForm === "New Treatment Plan" ||
          selectedForm === "New Appointment"
            ? `Add ${selectedForm}`
            : `Edit Patient - ${selectedForm}`}
        </strong>
      }
      width="65vw"
      extra={null}
      bodyStyle={{ padding: 0 }}
      onClose={() => {
        close();
        createTreatmentForm?.resetFields();
        console.log({ ...drawerProps });
      }}
    >
      {selectedForm === "New Treatment Plan" ||
      selectedForm === "New Appointment" ? (
        <Create
          headerProps={{
            extra: null,
            title: false,
          }}
          title={false}
          saveButtonProps={
            selectedForm === "New Treatment Plan"
              ? {
                  loading: saveButtonProps?.loading,
                  disabled: saveButtonProps?.disabled,
                  style: { width: "180px" },
                  onClick: () => {
                    createTreatmentForm.validateFields().then((val) => {
                      create(
                        {
                          resource: "treatments",
                          values: {
                            ...val,
                            patientID: record?.id,
                            updatedBy: user?.username,
                            createdBy: record?.createdBy,
                            patientPhone: `${record?.phoneNumber?.dialCode}${record?.phoneNumber?.number}`,
                            patientFullName: record?.fullName,
                            language: record?.language,
                          },
                        },
                        {
                          onSuccess: () => {
                            //notify
                            createTreatmentForm.resetFields();
                            close();
                          },
                        }
                      );
                    });
                  },
                }
              : { ...saveButtonProps, style: { width: "180px" }, }
          }
          breadcrumb={false}
          goBack={false}
        >
          {selectedForm === "New Treatment Plan" && (
            <Form
              form={createTreatmentForm}
              layout="vertical"
              initialValues={formProps.initialValues}
            >
              <TreatmentPlanForm record={record} form={createTreatmentForm} />
            </Form>
          )}
          {selectedForm === "New Appointment" && (
            <Form {...formProps} layout="vertical">
              <AppointmentsForm patientID={patientID} />
            </Form>
          )}
        </Create>
      ) : (
        <Edit
          headerProps={{
            extra: null,
            title: false,
          }}
          title={false}
          saveButtonProps={{
            loading: saveButtonProps?.loading,
            disabled: saveButtonProps?.disabled,
            style: { width: "180px" },
            onClick: () => {
              editForm.validateFields().then((val) => {
                mutate(
                  {
                    resource: "patients",
                    id: record?.id,
                    values: Object.assign(record, {
                      ...val,
                      updatedBy: user?.username,
                      createdBy: record?.createdBy,
                    }),
                  },
                  {
                    onSuccess: () => {
                      close();
                    },
                  }
                );
              });
            },
          }}
          canDelete={false}
          breadcrumb={false}
          goBack={false}
        >
          <Form
            form={editForm}
            layout="vertical"
            initialValues={formProps.initialValues}
          >
            {selectedForm === "Basic Information" && <BasicInformation />}
            {selectedForm === "Medical History" && <MedicalHistoryForm />}
          </Form>
        </Edit>
      )}
    </Drawer>
  );
};
