import React from "react";
import { Pie } from "@ant-design/plots";

 interface PieChartProps {
    data:any,
    colors? : any
 }

const PieChart: React.FC<PieChartProps> = (props) => {
    const {data, colors} = props
 
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    color: colors,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 12,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        // content: "Treatments",
      },
    },
  };
  return <Pie {...config} />;
};

export default PieChart;
