import { Row, Col, Card, Typography } from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";
import ColumnChart from "components/columnChart";
import PieChart from "components/pieChart";
import Stats from "components/stats";
import { useEffect, useState } from "react";
import adaptSurveyData from "utils/adaptSurveyData";

const { Text } = Typography;

export const DashboardPage: React.FC = () => {
  const [stats, setStats] = useState<any>({
    patients: null,
    mesaages: null,
  });

  const treatments = useList({
    resource: "treatments",
  });

  const patients = useList({
    resource: "patients",
  });

  const messages = useList({
    resource: "messages",
  });

  const surveys = useList({
    resource: "surveys",
  });

  const treatmentStatus = treatments?.data?.data.map((t) => t.status);

  const adaptTreatmentStatus = (arr: any[]) => {
    if (!arr) return;

    const uniqueObj: any = {};

    for (const element of arr) {
      if (uniqueObj[element]) {
        uniqueObj[element] += 1;
      } else {
        uniqueObj[element] = 1;
      }
    }

    const adapted = Object.entries(uniqueObj).map((unique) => {
      return {
        type: unique[0],
        value: unique[1],
      };
    });

    return adapted;
  };

  const patientsLength = patients?.data?.data?.filter(
    (p) => p.status !== "inactive"
  ).length;

  const messagesPerPatiens = messages?.data?.data?.reduce(
    (acc, element) => acc + element?.patients?.length || 2,
    0
  );

  const surveysAnsweredData: any = surveys?.data?.data
    ?.map((item) => adaptSurveyData(item?.questions, item?.answers))
    ?.filter((item) => item?.length > 1)
    ?.map((item) => item?.flat())
    ?.flat()
    ?.map((item: any) => item?.colorRange);

  const surevysAnswers = (arr: any[]) => {
    
    let obj: any = {};
    let countObj = {
      green: 0,
      orange: 0,
      red: 0,
    };

    for (let item of arr) {
      let keys = Object.keys(item);
      let vals = Object.values(item);

      let count = 0;

      for (let valIndex in vals) {
        if (vals[valIndex]) {
          if (keys[valIndex] === "green") {
            countObj["green"]++;
            count ++
          }

          if (keys[valIndex] === "orange") {
            countObj["orange"]++;
          }

          if (keys[valIndex] === "red") {
            countObj["red"]++;
          }

          obj[keys[valIndex]] = ++count;
        }
      }
    }

    const data = Object.entries(countObj).map((item) => {
      return {
        type: item[0],
        value: item[1],
      };
    });

    return data;
  };

  const surveyAnsweredData = [
    {
      type: "Fully Completed",
      value: 16,
    },
    // {
    //   type: "Droped off",
    //   value: 0,
    // },
    {
      type: "Pending",
      value: 26,
    },
    {
      type: "Not Started",
      value: 3,
    },
  ];

  


  const data = [
    {
      type: "week 1",
      sales: 0,
    },
    {
      type: "week 2",
      sales: 0,
    },
    {
      type: "week 3",
      sales: 2,
    },
    {
      type: "week 4",
      sales: 4,
    },
    {
      type: "week 5",
      sales: 1,
    },
    {
      type: "week 6",
      sales: 0,
    },
    {
      type: "week 7",
      sales: 0,
    },
  ];

  const data2 = [
    {
      type: "week 1",
      sales: 1,
    },
    {
      type: "week 2",
      sales: 0,
    },
    {
      type: "week 3",
      sales: 0,
    },
    {
      type: "week 4",
      sales: 1,
    },
    {
      type: "week 5",
      sales: 2,
    },
    {
      type: "week 6",
      sales: 0,
    },
    {
      type: "week 7",
      sales: 0,
    },
  ];

  return (
    <Row gutter={[32, 32]} style={{ padding: 16 }}>
      <Col md={24}>
        <Row gutter={[16, 16]}>
          <Col xl={8} lg={12} md={24} sm={24} xs={24}>
            <Card
              bodyStyle={{
                height: 280,
                padding: 10,
              }}
              title={<Text strong>Stats</Text>}
            >
              <div
                style={{ height: "240px" }}
                // onClick={() =>
                //   console.log(
                //     surveysAnsweredData
                //   )
                // }
              >
                <Stats
                  data={{
                    patients: patientsLength,
                    messages: messagesPerPatiens,
                  }}
                />
              </div>
            </Card>
          </Col>
          <Col xl={8} lg={12} md={24} sm={24} xs={24}>
            <Card
              bodyStyle={{
                // height: 270,
                padding: 10,
              }}
              title={
                <Text strong /* style={{ fontSize: 24, fontWeight: 800 }} */>
                  Surveys answers
                </Text>
              }
            >
              <div style={{ height: "240px" }} >
                <PieChart
                  data={surveysAnsweredData ? surevysAnswers(surveysAnsweredData) : []}
                  colors={["#56CA00", "#FFB547", "#FF4C51"]}
                />
              </div>
            </Card>
          </Col>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <Card
              bodyStyle={{
                // height: 270,
                padding: 10,
              }}
              title={<Text strong>Surveys answered</Text>}
            >
              <div style={{ height: "240px" }}>
                <PieChart data={surveyAnsweredData} />
              </div>
            </Card>
          </Col>
          <Col xl={10} lg={24} md={24} sm={24} xs={24}>
            <Card
              title="Tratments status"
              bodyStyle={{
                padding: 10,
                paddingBottom: 0,
              }}
              style={
                {
                  // backgroundRepeat: "no-repeat",
                  // backgroundPosition: "right",
                }
              }
            >
              <div style={{ height: "240px" }}>
                <PieChart
                  data={
                    treatmentStatus ? adaptTreatmentStatus(treatmentStatus) : []
                  }
                />
              </div>
            </Card>
          </Col>
          <Col xl={7} lg={12} md={24} sm={24} xs={24}>
            <Card
              title={
                <Text strong /* style={{ fontSize: 24, fontWeight: 800 }} */>
                  Average Duration of Cycle in Weeks
                </Text>
              }
              bodyStyle={{
                padding: 10,
                paddingBottom: 0,
              }}
              style={
                {
                  // backgroundRepeat: "no-repeat",
                }
              }
            >
              <div style={{ height: "240px" }}>
                <ColumnChart data={data} />
              </div>
            </Card>
          </Col>
          <Col xl={7} lg={12} md={24} sm={24} xs={24}>
            <Card
              title={
                <Text strong /* style={{ fontSize: 24, fontWeight: 800 }} */>
                  Treatment Terminations per Cycle
                </Text>
              }
              bodyStyle={{
                padding: 10,
                paddingBottom: 0,
              }}
              //   style={{

              //     backgroundRepeat: "no-repeat",
              //     backgroundPosition: "right",
              //   }}
            >
              <div style={{ height: "240px" }}>
                <ColumnChart data={data2} />
              </div>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
