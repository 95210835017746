import {
  Typography,
  Icons,
  Card,
  Avatar,
  Space,
  Button,
  DateField,
} from "@pankod/refine-antd";

import "./style.less";

import CardItem from "components/cardItem";
import calculateAge from "utils/getAge";

const {
  BankOutlined,
  IdcardOutlined,
  ReadOutlined,
  CompassOutlined,
  CalendarOutlined,
  HomeOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  FundProjectionScreenOutlined,
  EditOutlined,
} = Icons;

const UserInfoCard = (props: any) => {
  const { onEdit, record } = props;
  return (
    <Card
      className="card-shadow"
      headStyle={{ borderBottom: "none" }}
      style={{ backgroundColor: "#fff" }}
      title={
        <Space size={16}>
          <Avatar
            style={{ backgroundColor: "#00B0C7", verticalAlign: "middle" }}
            size="large"
          >
            {record?.fullName
              ?.split(" ")
              .map((item: any) => item[0])
              .join("")}
          </Avatar>

          <h3 className="card-title">{record?.fullName}</h3>
          <span style={{ fontSize: "12px", color: "#9F9DA3" }}>
            {`${record?.gender === 1 ? "Male" : "Female"}, ${calculateAge(
              record?.dateOfBirth
            )}`}
          </span>
        </Space>
      }
      extra={
        <Button type="link" icon={<EditOutlined />} onClick={onEdit}>
          Edit
        </Button>
      }
    >
      <Space direction="vertical">
        <Space size={32} wrap>
          <CardItem
            Icon={IdcardOutlined}
            label="Patient ID"
            val={record?.patientId}
          />
          <CardItem
            Icon={CalendarOutlined}
            label="Date of Birth"
            val={<DateField value={record?.dateOfBirth} format="DD/MM/YYYY" />}
          />
          <CardItem
            Icon={PhoneOutlined}
            label="Phone Number"
            val={record?.phoneNumber ? `${record?.phoneNumber?.dialCode}${record?.phoneNumber?.number}` : ""}
          />
        </Space>

        <Space size={32} wrap>
          <CardItem
            Icon={CompassOutlined}
            label="District"
            datakey="district"
            valkey={record?.district}
          />
          <CardItem
            Icon={EnvironmentOutlined}
            label="Vilage"
            val={record?.village}
          />
          <CardItem Icon={HomeOutlined} label="Address" val={record?.address} />
        </Space>

        <Space size={32} wrap>
          <CardItem
            Icon={BankOutlined}
            label="Religion"
            datakey="religion"
            valkey={record?.religion}
          />
          <CardItem
            Icon={ReadOutlined}
            label="Level of Education"
            datakey="levelOfEducation"
            valkey={record?.levelOfEducation}
          />
          <CardItem
            Icon={FundProjectionScreenOutlined}
            label="Employment Status"
            datakey="employmentStatus"
            valkey={record?.employmentStatus}
          />
        </Space>
      </Space>
    </Card>
  );
};

export default UserInfoCard;
