import {
  Typography,
  Icons,
  Card,
  Space,
  Button,
  Divider,
  DateField,
  TextField,
  useModal,
  Modal,
} from "@pankod/refine-antd";

import "./style.less";

import CardItem from "components/cardItem";
import { useUpdate } from "@pankod/refine-core";
import { toFullName } from "utils/usernameTransform";
import { useState } from "react";


const { PlusOutlined, MedicineBoxOutlined, SolutionOutlined } = Icons;

const { Title } = Typography;
const { CalendarOutlined } = Icons;

//fetch from definitions
const therapies = [
  { type: "surgery", label: "Surgery" },
  { type: "radiotherapy", label: "Radiotherapy" },
  { type: "chemotherapy", label: "Chemotherapy" },
  { type: "chemotherapyAC", label: "Chemotherapy (AC)" },
  { type: "hormonalTherapy", label: "Hormonal Therapy" },
  { type: "bisphosphonate", label: "Bisphosphonate" },
  { type: "targetedTherapy", label: "Targeted Therapy" },
];

const CurrentTreatmentCard = (props: any) => {
  const { onHistory, record, onAddNew } = props;
  const [selectedTreatment, setSelectedTreatment] = useState("");

  const { modalProps, show, close } = useModal();
  const { mutate, isLoading } = useUpdate<any>();

  const onModalOk = () => {
    // alert(selectedTreatment);
    mutate({
      resource: "treatments",
      id: record?.id,
      values: {
        treatment: selectedTreatment,
        cycle: {
          ...record?.[selectedTreatment].cycle,
          current: record?.[selectedTreatment].cycle?.current + 1,
        },
        id: record?.id,
        createdAt: record?.createdAt
      },
      metaData: {
        httpMethod: "patch",
      },
    });

    close();
  };

  return (
    <>
      <Card
        className="card-shadow treatment-plan-card"
        style={{ backgroundColor: "#fff" }}
        title={
          <Space direction="vertical" size={0}>
            <Space direction="vertical" size={0}>
              <h3 className="card-title">Current Treatment Plan</h3>
              <span style={{ fontSize: "12px", color: "#9F9DA3" }}>
                <CalendarOutlined />
                {record?.createdAt ? (
                  <DateField
                    value={record?.createdAt}
                    format="DD/MM/YYYY"
                    style={{ fontSize: "12px", color: "#9F9DA3", margin: 4 }}
                  />
                ) : (
                  <></>
                )}
              </span>
            </Space>
            <span style={{ fontSize: "13px", color: "#9F9DA3" }}>
              <MedicineBoxOutlined />
              <TextField
                value={toFullName(record?.createdBy)}
                style={{ fontSize: "12px", color: "#9F9DA3", margin: 4 }}
              />
            </span>
          </Space>
        }
        extra={
          <Space direction="vertical">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{ width: "100%" }}
              onClick={onAddNew}
            >
              New Plan
            </Button>
            <Button type="link" icon={<SolutionOutlined />} onClick={onHistory}>
              History
            </Button>
            <Space></Space>
          </Space>
        }
        // actions={[
        //   <Space size={48} wrap>
        //     <span>
        //       <span>Number of Cycles: </span>
        //       {record?.cycle?.current && record?.cycle?.numberOf ? (
        //         <strong>{`${record?.cycle?.current}/${record?.cycle?.numberOf}`}</strong>
        //       ) : (
        //         <strong>
        //           {record?.isPatientCompliant === false
        //             ? "Non-compliant"
        //             : "Not Configured"}
        //         </strong>
        //       )}
        //     </span>
        //     {record?.cycle?.current &&
        //     record?.cycle?.numberOf &&
        //     record?.status === "Ongoing" ? (
        //       <Button type="primary" onClick={show}>
        //         Finish Cycle
        //       </Button>
        //     ) : (
        //       <></>
        //     )}
        //   </Space>,
        // ]}
      >
        <Space direction="vertical">
          {/* <CardItem
            // label="Surgery"
            datakey="surgery"
            valkey={record?.surgery}
            spanwidth="280px"
          />
          <CardItem
            // label="Radiotherapy"
            datakey="radiotherapy"
            valkey={record?.radiotherapy}
            spanwidth="280px"
          />
          <CardItem
            label="Chemotherapy"
            datakey="chemotherapy"
            valkey={record?.chemotherapy}
            spanwidth="280px"
          />
          <CardItem
            label="Hormonal therapy"
            datakey="hormonalTherapy"
            valkey={record?.hormonalTherapy}
            spanwidth="280px"
          /> */}
          {therapies.map((therapy, index) => {
            if (
              record?.[therapy?.type]?.type ||
              record?.[therapy?.type]?.type === 0
            ) {
              return (
                <div key={`${therapy?.label}_${index}`}>
                  <span style={{ width: "100%", display:"flex" }}>
                    <b>{therapy?.label}</b>

                    <div
                      style={{
                        position:
                        record?.[therapy?.type]?.status === "Finished" 
                            ? "relative"
                            : "absolute",
                         right: 16   
                      }}
                    >
                      {!(
                        therapy.type === "surgery" ||
                        therapy.type === "hormonalTherapy"
                      ) && (
                        <>
                          {record?.[therapy?.type]?.status === "Finished" ? (
                            <></>
                          ) : (
                            <Button
                              loading={isLoading}
                              size="small"
                              type="primary"
                              onClick={() => {
                                setSelectedTreatment(therapy?.type);
                                show();
                              }}
                            >
                              Finish Cycle
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </span>

                  <Space direction="vertical" size={0}>
                    <CardItem
                      datakey={therapy?.type}
                      valkey={record?.[therapy?.type]?.type}
                      spanwidth="300px"
                    />
                    <span>
                      <b style={{ fontSize: 12 }}>Start Date:</b>{" "}
                      <DateField
                        value={record?.[therapy?.type]?.startDate}
                        format="DD/MM/YYYY"
                      />
                    </span>

                    {!(
                      therapy.type === "surgery" ||
                      therapy.type === "hormonalTherapy"
                    ) && (
                      <span>
                        <b style={{ fontSize: 12 }}>Number of Cycles:</b>{" "}
                        {`${record?.[therapy?.type]?.cycle?.current}/${
                          record?.[therapy?.type]?.cycle?.numberOf
                        }`}
                      </span>
                    )}
                  </Space>

                  <Divider style={{ padding: 0, margin: 8 }} />
               
                </div>
              );
            }
          })}
        </Space>{" "}
      </Card>
      <Modal
        onOk={onModalOk}
        {...modalProps}
        title="Confirmation"
        okText={
          record?.[selectedTreatment]?.cycle.current ===
          record?.[selectedTreatment]?.cycle.numberOf
            ? "Finish Treatment"
            : "Finish Cycle"
        }
      >
        {record?.[selectedTreatment]?.cycle.current ===
        record?.[selectedTreatment]?.cycle.numberOf
          ? `You're about to confirm the patient ${
              record?.patientFullName
            } has finished the last cycle of prescribed therapy for ${selectedTreatment.toUpperCase()} treatment`
          : `You're about to confirm the patient ${
              record?.patientFullName
            } has finished another cycle of prescribed therapy for ${selectedTreatment.toUpperCase()} treatment`}
      </Modal>
    </>
  );
};

export default CurrentTreatmentCard;
