import React, { FC, useContext, useState } from "react";
import {
  Form,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Input,
  Divider,
} from "@pankod/refine-antd";
import Selects from "components/selects";
import { UserDataContext } from "App";
import dayjs from "dayjs";

interface ITreatmentPlanFormProps {
  record?: any;
  treatmentIndex?: any;
  form?: any;
}

const { Option } = Select;

//fetch from definitions
const therapies = [
  { type: "surgery", label: "Surgery" },
  { type: "radiotherapy", label: "Radiotherapy" },
  { type: "chemotherapy", label: "Chemotherapy" },
  { type: "chemotherapyAC", label: "Chemotherapy (AC)" },
  { type: "hormonalTherapy", label: "Hormonal Therapy" },
  { type: "bisphosphonate", label: "Bisphosphonate" },
  { type: "targetedTherapy", label: "Targeted Therapy" },
];

export const TreatmentPlanForm: FC<ITreatmentPlanFormProps> = (props) => {
  const { record, form } = props;
  const user = useContext(UserDataContext);
  const [isPatientCompliant, setIsPatientCompliant] = useState<boolean>(true);
  const [isPatientUndergoing, setIsPatientUndergoing] =
    useState<boolean>(false);

  const [isTreatmentErrorVisibe, setIsTreatmentErrorVisibe] =
    useState<boolean>(false);

  const [treatmentsType, setTreatmentsType] = useState<any>({});

  const isGoingChange = (e: boolean) => {
    setIsPatientUndergoing(e);
    form.setFieldsValue({ isPatientCompliant: true });
    setIsPatientCompliant(true);
  };

  const validateTreatments = ({ getFieldValue }: any) => ({
    validator() {
      if (
        getFieldValue(["surgery", "type"])?.toString() ||
        getFieldValue("radiotherapy", "type")?.toString() ||
        getFieldValue("chemotherapy", "type")?.toString() ||
        getFieldValue("hormonalTherapy", "type")?.toString() ||
        getFieldValue("chemotherapyAC", "type")?.toString() ||
        getFieldValue("bisphosphonate", "type")?.toString() ||
        getFieldValue("targetedTherapy", "type")?.toString()
      ) {
        setIsTreatmentErrorVisibe(false);
        return Promise.resolve();
      }
      setIsTreatmentErrorVisibe(true);
      return Promise.reject(new Error(""));
    },
  });

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Is the patient currently undergoing treatment?"
            name="isPatientUndergoing"
            initialValue={false}
            rules={[{ required: true }]}
          >
            <Select onChange={isGoingChange}>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {isPatientUndergoing && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Is the patient compliant with the treatment?"
              name="isPatientCompliant"
              initialValue={true}
              rules={[{ required: true }]}
            >
              <Select
                onChange={setIsPatientCompliant}
                value={isPatientCompliant}
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}

      <>
        <Divider orientation="left">Treatments</Divider>

        {therapies.map((therapy) => (
          <Row gutter={16} key={therapy.type}>
            <Col span={isPatientUndergoing ? 9 : 12}>
              <Form.Item
                label={therapy.label}
                name={[therapy.type, "type"]}
                rules={[validateTreatments]}
                dependencies={therapies
                  .map((t) => t.type)
                  .filter((t: any) => t !== therapy.type)}
              >
                <Selects
                  datakey={therapy.type}
                  allowClear
                  onChange={(e) =>
                    setTreatmentsType({
                      ...treatmentsType,
                      [therapy.type]: e,
                    })
                  }
                />
              </Form.Item>
            </Col>
            {(treatmentsType[therapy.type] ||
              treatmentsType[therapy.type] === 0) && (
              <>
                <Col span={isPatientUndergoing ? 5 : 6}>
                  <Form.Item
                    label={therapy.type === "surgery" ? "Date" : "Start Date"}
                    name={[therapy.type, "startDate"]}
                    rules={[
                      {
                        required: true,
                        message:
                          therapy.type === "surgery"
                            ? "Date field is required"
                            : "Start Date field is required",
                      },
                    ]}
                    // dependencies={therapies.filter(t => t !== therapy)}
                  >
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      style={{ width: "100%" }}
                      onChange={() => console.log(treatmentsType)}
                    />
                  </Form.Item>
                </Col>

                {!(
                  therapy.type === "surgery" ||
                  therapy.type === "hormonalTherapy"
                ) && (
                  <Col span={isPatientUndergoing ? 5 : 6}>
                    <Form.Item
                      label={
                        therapy.type === "radiotherapy"
                          ? "Number of Sessions"
                          : "Number of Cycles"
                      }
                      name={[therapy.type, "cycle", "numberOf"]}
                      validateTrigger={["onChange", "onSubmit"]}
                      initialValue={2}
                      rules={[
                        {
                          required: true,
                          message:
                            therapy.type === "radiotherapy"
                              ? "Number of Cycles field is required"
                              : "Number of Sessions is required",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              getFieldValue([
                                therapy.type,
                                "cycle",
                                "current",
                              ]) <= value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                therapy.type === "radiotherapy"
                                  ? "No of Cycles can't be less than Current Cycle"
                                  : "No of Sessions can't be less than Current Session"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <InputNumber min={1} max={30} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                )}

                {!(
                  therapy.type === "surgery" ||
                  therapy.type === "hormonalTherapy"
                ) && (
                  <Col span={isPatientUndergoing ? 5 : 6}>
                    <Form.Item
                      label={
                        therapy.type === "radiotherapy"
                          ? "Current Cycle"
                          : "Current Session"
                      }
                      name={[therapy.type, "cycle", "current"]}
                      hidden={!isPatientUndergoing}
                      initialValue={1}
                      rules={[
                        {
                          required: true,
                          message:
                            therapy.type === "radiotherapy"
                              ? "Current Cycle field is required"
                              : "Current Session is required",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              getFieldValue([
                                therapy.type,
                                "cycle",
                                "numberOf",
                              ]) >= value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                therapy.type === "radiotherapy"
                                  ? " Current Cycle can't be greater than No of Cycles"
                                  : "Current Session can't be greater than No of Sessions"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <InputNumber min={1} max={30} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                )}

                <Form.Item
                  hidden
                  name={[therapy.type, "status"]}
                  initialValue="Ongoing"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  hidden
                  name={[therapy.type, "cycleDates"]}
                  initialValue={[]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
          </Row>
        ))}

        <span
          style={{
            visibility: !isTreatmentErrorVisibe ? "hidden" : "visible",
            color: "#fa541c",
          }}
        >
          At least one of all treatment category must have a value
        </span>
      </>
    </>
  );
};
