import { CSSProperties } from "react";

export const antLayoutSider: CSSProperties = {
  position: "relative",
};
export const antLayoutSiderMobile: CSSProperties = {
  position: "fixed",
  height: "100vh",
  zIndex: 10000,
  // borderRightColor:  "red",
  // borderRightWidth: "1px",
  // borderRightStyle: "solid"
};
