const addZeroPrefixToNumber = (number: number | string): string => {
  let numberWithPrefix = number.toString();

  if (number <= 9) {
    numberWithPrefix = `0${number}`;
  }

  return numberWithPrefix;
};

export default addZeroPrefixToNumber;