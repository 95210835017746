import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  Edit,
  Form,
  Button,
  SaveButton,
  useStepsForm,
  Steps,
  Icons,
  Space,
} from "@pankod/refine-antd";

import { BasicInformation } from "./forms/basicInformation";
import { MedicalHistoryForm } from "./forms/medicalHistory";

const { Step } = Steps;

export const PatientEdit: React.FC<IResourceComponentsProps> = () => {
  const { current, gotoStep, stepsProps, formProps, saveButtonProps, form } =
    useStepsForm({ redirect: "list" });

  const formList = [
    <BasicInformation isFullScreen />,
    <MedicalHistoryForm isFullScreen form={form} />,
    // <TreatmentPlanEditForm />,
  ];

  return (
    <Edit
      breadcrumb={false}
      headerButtons={null}
      // headerProps={{ style: { backgroundColor: '#fbfbfb' } }}
      // contentProps={{ style: { backgroundColor: '#fbfbfb' } }}
      // wrapperProps={{ style: { backgroundColor: '#fbfbfb' } }}

      footerButtons={
        <Space align="center" style={{ backgroundColor: "#fbfbfb" }}>
          {current > 0 && (
            <Button
              style={{ width: "140px" }}
              onClick={() => {
                gotoStep(current - 1);
              }}
            >
              Previous Step
            </Button>
          )}
          {current < formList.length - 1 && (
            <Button
              style={{ width: "140px" }}
              onClick={() => {
                gotoStep(current + 1);
              }}
            >
              Next Step
            </Button>
          )}

          <SaveButton {...saveButtonProps} style={{ width: "140px" }} />
        </Space>
      }
    >
      <Steps {...stepsProps} type="navigation" style={{ width: "50%" }}>
        <Step title="Basic Information" icon={<></>} />
        <Step title="Medical History" icon={<></>} />
      </Steps>

      <Form
        {...formProps}
        layout="vertical"
        style={{ marginTop: 30, backgroundColor: "#fbfbfb" }}
      >
        {formList[current]}
      </Form>
    </Edit>
  );
};
