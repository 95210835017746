import { Form, Input } from "@pankod/refine-antd";

export const SupportForm = () => {
  return (
    <>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "Please enter the valid Email",
          },
        ]}
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item
        label="Text"
        name="text"
        rules={[
          {
            required: true,
            message: "Text field is required"
          },
        ]}
      >
        <Input.TextArea rows={6} />
      </Form.Item>
    </>
  );
};
