import questionarre from 'assets/data/survey.json';

const adaptSurveyData = (questions: any[], answers: any[]): any[] => {
  const survey: any[] = questionarre;

  const joinedQuestionsAndAnswers = questions?.map?.((question, qIndex, all) => {
    const { title, grades } = survey.find((val: any) => val.id === question.id);
    const grade = answers.find((answer, aIndex) => aIndex === qIndex)?.answer;
    const createdAt = answers.find((answer, aIndex) => aIndex === qIndex)?.createdAt;
    const gradeTitle = grades.find((val: any) => val.value === Number(grade))?.title;
    const isFinished = answers.length >= all.length

    const nGrade = Number(grade)

    const green = (grades.length > 3 && nGrade < 3) || (grades.length <= 3 && nGrade < 2)
    const orange = (grades.length === 5 && nGrade === 3) || (grades.length === 6 && (nGrade === 3 || nGrade === 4)) || (grades.length === 3 && nGrade === 2)
    const red = (grades.length === 5 && nGrade > 3) || (grades.length === 6 && nGrade > 4) || (grades.length === 3 && nGrade > 2) || (grades.length === 3 && nGrade === 2)
    const colorRange = { green, orange, red }

    return { ...question, grade, title, grades, gradeTitle, createdAt, isFinished, colorRange };
  });

  return joinedQuestionsAndAnswers;
}

export function getSeveritySimptoms(str: string)   {
  const excludedWords = ["SEVERITY", "WORST", "I", "YOUR"];
  const words = str.match(/[A-Z]+/g) || [];
  const filteredWords = words.filter(word => !excludedWords.includes(word));
  return filteredWords.join(" ");
}

export default adaptSurveyData;