//@ts-expect-error
import connect from "amazon-connect-streams";






import { useEffect, useRef } from "react";



// const connectRTC = new ConnectRTC({
//     ccpUrl: '<your CCP URL>',
//     loginPopup: true,
//     softphone: {
//       allowFramedSoftphone: true
//     },
//     chat: {
//       enabled: true,
//       allowCustomerToSeeEachOther: true
//     }
//   });
export const CallCenter = () => {
  let contact: any;

  let container = useRef(null)

  const initConnect = () => {
    connect?.core?.initCCP?.(container?.current, {
      ccpUrl: "YOUR_CCP_URL",
      loginPopup: true,
      loginPopupAutoClose: true,
      softphone: {
        allowFramedSoftphone: true,
      },
    });

    const agent = connect?.core?.getAgent();

    agent?.onRefresh((data:any) => {
      if (data.contacts.length > 0) {
        contact = data.contacts[0];
        contact.onAccepted(() => {
          const contactId = contact.getContactId();
          console.log("Contact ID:", contactId);
        });
      }
    });
  };

  useEffect(() => initConnect(),[])


  return <div ref={container}></div>
};
