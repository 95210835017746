export const calcBSA = (h: number, w: number) => (Math.sqrt((h * w) / 3600)).toFixed(2)
export const calcBMI = (h: number, w: number) => ((w / (h * h)) * 10000).toFixed(2)
export const getRange  = (start: number, end: number) => {
    const result = [];
  
    for (let i = start; i < end; i++) {
      result.push(i);
    }
  
    return result;
  };