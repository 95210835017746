import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./i18n";
import { Spin } from "@pankod/refine-antd";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
Amplify.configure(config);

ReactDOM.render(
  <AmplifyProvider>
    <React.StrictMode>
      <React.Suspense
        fallback={
          <div className="spiner">
            <Spin size="large" />
          </div>
        }
      >
        <App />
      </React.Suspense>
    </React.StrictMode>
  </AmplifyProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
