import React, { useState } from "react";

import {
  useTranslate,
  useTitle,
  CanAccess,
  ITreeMenu,
  useMenu,
  useRefineContext,
  useRouterContext,
} from "@pankod/refine-core";
import { AntdLayout, Menu, Grid, Icons, Divider } from "@pankod/refine-antd";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";

const { UnorderedListOutlined } = Icons;
const dividerStyle = {
  borderColor: "#9BA2A7",
  borderWidth: 0.5,
  color: "#9BA2A7",
  fontSize: "12px",
  margin: 4,
  opacity: 0.7,
};

interface ISiderProps {
  groups: string[];
}

export const Sider: React.FC<ISiderProps> = (props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const { Link } = useRouterContext();
  const { hasDashboard } = useRefineContext();
  const Title = useTitle();
  const { SubMenu } = Menu;

  const translate = useTranslate();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const breakpoint = Grid.useBreakpoint();

  const isMobile = breakpoint.lg;

  const isAdmin = true; //dovuci preko propsa iza App.tsx

  const adminNames = ["users", "definitions"];
  const appNames = ["patients"];

  const renderAdmin = (tree: ITreeMenu[], selectedKey: string) => {
    if (!isAdmin) return <></>;

    return tree.map((item: ITreeMenu) => {
      const { icon, label, route, name, children, parentName } = item;
      if (!adminNames.includes(name)) return <></>;
      const isSelected = route === selectedKey;
      const isRoute = !(parentName !== undefined && children.length === 0);

      return (
        <CanAccess key={route} resource={name.toLowerCase()} action="list">
          <Menu.ItemGroup
            title={
              <Divider style={dividerStyle} plain orientation="left">
                App
              </Divider>
            }
            key={"divider-app"}
          />
          <Menu.Item
            key={route}
            style={{
              fontWeight: isSelected ? "bold" : "normal",
            }}
            icon={icon ?? (isRoute && <Icons.SolutionOutlined />)}
          >
            <Link to={route}>{label}</Link>
            {/* {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )} */}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { icon, label, route, name, children, parentName } = item;
      // if (adminNames.includes(name)) return <></>;
      // if (children.length > 0) {
      //   return (
      //     <SubMenu
      //       key={route}
      //       icon={icon ?? <UnorderedListOutlined />}
      //       title={label}
      //     >
      //       {renderTreeView(children, selectedKey)}
      //     </SubMenu>
      //   );
      // }
      const isSelected = route === selectedKey;
      const isRoute = !(parentName !== undefined && children.length === 0);
      return (
        <CanAccess key={route} resource={name.toLowerCase()} action="list">
          {/* <Menu.ItemGroup
            title={
              <Divider style={dividerStyle} plain orientation="left">
                App
              </Divider>
            }
            key={"divider-app"}
          /> */}
          <Menu.Item
            key={route}
            style={{
              fontWeight: isSelected ? "bold" : "normal",
            }}
            icon={icon ?? (isRoute && <Icons.SolutionOutlined />)}
          >
            <Link to={route}>{label}</Link>
            {/* {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )} */}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={0}
      breakpoint="xxl"
      style={antLayoutSiderMobile}
    >
      {Title && <Title collapsed={collapsed} />}
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        onClick={() => {
          // if (!breakpoint.lg) {
          setCollapsed(true);
          // }
        }}
      >
        {hasDashboard && (
          <Menu.Item
            key="dashboard"
            style={{
              fontWeight: selectedKey === "/" ? "bold" : "normal",
            }}
            icon={<Icons.DashboardOutlined />}
          >
            <Link to="/">Dashboard</Link>
          </Menu.Item>
        )}

        {/* <Menu.Item
          key="users"
          style={{
            fontWeight: selectedKey === "/users" ? "bold" : "normal",
          }}
          icon={<Icons.TeamOutlined />}
        >
          <Link to="/users">User Management</Link>
        </Menu.Item>

        <Menu.Item
          key="definitions"
          style={{
            fontWeight: selectedKey === "/definitions" ? "bold" : "normal",
          }}
          icon={<Icons.FileTextOutlined />}
        >
          <Link to="/definitions">Definitions</Link>
        </Menu.Item> */}

        {renderTreeView(menuItems, selectedKey)}
      </Menu>
    </AntdLayout.Sider>
  );
};
