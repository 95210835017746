import { Col, Row } from "@pankod/refine-antd";

import { matchValue } from "../../../../utils/getPatientsData";

const ExpandedTreatmentPlan = ({ record }: any) => (
  <>
    <Row gutter={16}>
      {
        <Col span={12}>
          <p>
            <strong>Surgery: </strong>
            {record?.surgery || record.surgery === 0  ? matchValue(record.surgery, "surgery") : "/"}
          </p>
        </Col>
      }

      <Col span={12}>
        <p>
          <strong>Radiotherapy: </strong>
          {record?.radiotherapy || record?.radiotherapy === 0
            ? matchValue(record.radiotherapy, "surgery")
            : "/"}
        </p>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <strong>Chemotherapy: </strong>
        {record?.chemotherapy || record?.chemotherapy === 0
          ? matchValue(record.chemotherapy, "surgery")
          : "/"}
      </Col>

      <Col span={12}>
        <p>
          <strong>Hormonal Therapy: </strong>
          {record?.hormonalTherapy || record?.chemotherapy === 0
            ? matchValue(record.hormonalTherapy, "surgery")
            : "/"}
        </p>
      </Col>
    </Row>
  </>
);

export default ExpandedTreatmentPlan;
