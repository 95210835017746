/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "bd3503caf75341eeb5129cdb15cdd4b3",
    "aws_mobile_analytics_app_region": "eu-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "bd3503caf75341eeb5129cdb15cdd4b3",
            "region": "eu-west-2"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://uyyg9m1654.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "appointmentsAPI",
            "endpoint": "https://oac5vvvmf7.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "messagesAPI",
            "endpoint": "https://svvmlb8f4e.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "patientsAPI",
            "endpoint": "https://k1927e7hxg.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "supportAPI",
            "endpoint": "https://8zyo78vofl.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "surveysAPI",
            "endpoint": "https://xowrsdgsxl.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "treatmentsAPI",
            "endpoint": "https://i0cnrqeghh.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:831e0178-b45d-4485-8376-dbb071707d3c",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_Zaui6BWm4",
    "aws_user_pools_web_client_id": "5l1825v0ucbm6fnrncocqrd9rn",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "appointmentsDB-dev",
            "region": "eu-west-2"
        },
        {
            "tableName": "messagesDB-dev",
            "region": "eu-west-2"
        },
        {
            "tableName": "patientsDB-dev",
            "region": "eu-west-2"
        },
        {
            "tableName": "supportDB-dev",
            "region": "eu-west-2"
        },
        {
            "tableName": "surveysDB-dev",
            "region": "eu-west-2"
        },
        {
            "tableName": "treatmentsDB-dev",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
