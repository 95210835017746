import {
  Show,
  Typography,
  Tag,
  MarkdownField,
  Tabs,
  Icons,
  Card,
  Avatar,
  Space,
  RefreshButton,
  Button,
  Divider,
  DateField,
} from '@pankod/refine-antd';
import './style.less';

import CardItem from 'components/cardItem';
import { BusinessIcon } from 'components/icons/business';

const { PlusOutlined, IdcardOutlined, EditOutlined, SolutionOutlined } = Icons;

const { Title } = Typography;

const MedicalHistoryCard = (props: any) => {
  const { onEdit, record } = props;

  return (
    <Card
      className="card-shadow"
      headStyle={{ borderBottom: 'none' }}
      style={{ backgroundColor: '#fff' }}
      title={<h3 className="card-title">Medical History</h3>}
      extra={
        <Button type="link" icon={<EditOutlined />} onClick={onEdit}>
          Edit
        </Button>
      }
    >
      <Space direction="vertical">
        <Space size={32} wrap>
          <CardItem
            label="Primary Complaints: Breast"
            datakey="primaryComplaintsBreast"
            valkey={record?.primaryComplaintsBreast}
          />
        </Space>

        <Space size={32} wrap>
          <CardItem
            label="Primary Complaints: Other"
            datakey="primaryComplaintsOther"
            valkey={record?.primaryComplaintsOther}
          />
        </Space>

        <Space size={32} wrap>
          <CardItem label="Other Complaints" val={record?.otherComplaints} />
        </Space>

        <Space size={32} wrap>
          <CardItem
            label="First Sought Advice/Treatment"
            datakey="firstAdviceOrTreatment"
            valkey={record?.firstAdviceOrTreatment}
          />
        </Space>

        <Space size={32} wrap>
          <CardItem label="Histology" datakey="histology" valkey={record?.histology} />
          <CardItem label="Cancer Stage" datakey="cancerStage" valkey={record?.cancerStage} />
        </Space>

        <Space size={32} wrap>
          <CardItem
            label="Biological subtype"
            datakey="biologicalSubtype"
            valkey={record?.biologicalSubtype}
          />
        </Space>

        <Space size={32} wrap>
          <CardItem
            label="Had Symptoms since"
            val={<DateField value={record?.hadSymptomsSince} />}
          />
          <CardItem label="Age at first menstrual period" val={record?.firstMenstrualPeriod} />
        </Space>

        <Space size={32} wrap>
          <CardItem
            label="Past Medical History"
            datakey="pastMedicalHistory"
            valkey={record?.pastMedicalHistory}
          />
        </Space>

        <Space size={32} wrap>
          <CardItem
            label="Family History"
            datakey="familyHistory"
            datakeyNested="siblings"
            valkey={record?.familyHistory}
          />
        </Space>
      </Space>
    </Card>
  );
};

export default MedicalHistoryCard;
