import { FC, useEffect, useState } from "react";
import {
  useTable,
  List,
  Table,
  Button,
  Icons,
  DateField,
  Space,
} from "@pankod/refine-antd";
import { BaseKey, useList, useOne } from "@pankod/refine-core";
import ExpandedTreatmentPlan from "./ExpandedTreatmentPlan";
import { toFullName } from "utils/usernameTransform";

// import './style.less';

interface ITreatmentTabPane {
  patientID: string | BaseKey;
  onAddNew?: any; //(id?: BaseKey) => void
}

const { PlusOutlined, UpOutlined, DownOutlined } = Icons;

export const TreatmentTabPane: FC<ITreatmentTabPane> = (props) => {
  const { patientID, onAddNew } = props;
  const [tableData, setTableData] = useState<any>();

  const treatmentsQuery: any = useList({
    resource: "treatments",
    // id: patientID,
    config: {
      filters: [
        {
          field: "patientID",
          operator: "eq",
          value: patientID,
        },
      ],
    },
    queryOptions: {
      enabled: patientID ? true : false,
    },
  });

  useEffect(() => {
    setTableData(treatmentsQuery?.data?.data);

    return () => {};
  }, [treatmentsQuery?.data?.data]);

  const columns = [
    {
      title: "Prescribed by	",
      dataIndex: "createdBy",
      key: null,
      render: (val: any) => toFullName(val),
    },
    {
      title: "Start Date	",
      dataIndex: "createdAt",
      key: null,
      render: (val: any) => <DateField value={val} format="DD/MM/YYYY" />,
    },
    {
      title: "End Date	",
      dataIndex: "endAt",
      key: null,
      render: (val: any) =>
        val?.endAt ? <DateField value={val} format="DD/MM/YYYY" /> : "/",
    },
    { title: "No of Cycles	", dataIndex: ["cycle", "numberOf"], key: null },
    { title: "Current Cycle	", dataIndex: ["cycle", "current"], key: null },
    { title: "Status", dataIndex: "status", key: null },
    Table.EXPAND_COLUMN,
  ];

  return (
    <List
      breadcrumb={false}
      headerProps={{
        title: "Treatment Plan History",
        extra: (
          <Button type="primary" icon={<PlusOutlined />} onClick={onAddNew}>
            New Treatment Plan
          </Button>
        ),
      }}
    >
      <Table
        // className="treatment-plan-table"
        pagination={{ position: ["bottomCenter"] }}
        dataSource={tableData}
        columns={columns}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => (
            <ExpandedTreatmentPlan record={record} />
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <Button type="link" onClick={(e) => onExpand(record, e)}>
                <Space>
                  <span>Hide Details</span>
                  <UpOutlined />
                </Space>
              </Button>
            ) : (
              <Button type="link" onClick={(e) => onExpand(record, e)}>
                <Space>
                  <span>View Details</span>
                  <DownOutlined />
                </Space>
              </Button>
            ),
        }}
      />
    </List>
  );
};
