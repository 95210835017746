import { FC } from 'react';
import jsonData from '../../assets/data/patientData.json';
import { Select as AntdSelect, SelectProps } from '@pankod/refine-antd';

interface ISelectsProps extends SelectProps {
  datakey: string;
  width?: number;
}

const patientsData: any = jsonData;
const { Option } = AntdSelect;

const Selects: FC<ISelectsProps> = (props) => {
  const { datakey, width } = props;

  return (
    <AntdSelect
      style={{ width: `${width}px` || '220px' }}
      {...props}
      options={patientsData[datakey]}
      showArrow
    />
    //   {patientsData?.[datakey]?.map((option: any) => (
    //     <Option key={option?.value} value={option?.value}>
    //       {option?.label}
    //     </Option>
    //   ))}
    // </AntdSelect>
  );
};

export default Selects;
