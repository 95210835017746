export const validateFamilyHistory = [
  {
    validator(_: any, value: any) {
      if (!value || !Object.values(value).some((val: any) => val === null)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error());
    }, message: 'Please select an option.'
  }
];
