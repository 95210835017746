import { DataProvider } from "@pankod/refine-core";
import { API } from "aws-amplify";

const amplifyDataProvider = (): DataProvider => {
  return {
    getList: async ({
      resource,
      hasPagination = false,
      pagination,
      sort,
      filters,
      metaData,
    }) => {
      const filters_: any = filters;
      const obj: any = {};
      const key: any = filters_?.[0]?.field;
      const value = filters_?.[0]?.value;
      obj[key] = value;
      return API.get(`${resource}API`, `/${resource}`, {
        queryStringParameters: key && value ? obj : {},
      });

      // const { current = 1, pageSize = 10 } = pagination ?? {};

      // const sortBy = genereteSort(sort);
      // const filterBy = generateFilter(filters);

      // const camelResource = camelCase(resource);

      // const operation = metaData?.operation ?? camelResource;

      // const { query, variables } = gql.query({
      //     operation,
      //     variables: {
      //         ...metaData?.variables,
      //         sort: sortBy,
      //         where: { value: filterBy, type: "JSON" },
      //         ...(hasPagination
      //             ? {
      //                   start: (current - 1) * pageSize,
      //                   limit: pageSize,
      //               }
      //             : {}),
      //     },
      //     fields: metaData?.fields,
      // });

      // const response = await client.request(query, variables);
    },

    getMany: () => {
      throw Error("Not implemented.");
    },

    create: ({ resource, variables }) => {
      return API.post(`${resource}API`, `/${resource}`, { body: variables });
    },

    createMany: () => {
      throw Error("Not implemented.");
    },
    update: ({ resource, id, variables }) => {
      return API.patch(`${resource}API`, `/${resource}`, { body: variables });
    },

    updateMany: () => {
      throw Error("Not implemented.");
    },

    getOne: ({ resource, id, metaData }) => {
      return API.get(`${resource}API`, `/${resource}/${id}`, {});
    },

    deleteOne: () => {
      throw Error("Not implemented.");
    },

    deleteMany: () => {
      throw Error("Not implemented.");
    },

    getApiUrl: () => {
      throw Error("Not implemented.");
    },

    custom: () => {
      throw Error("Not implemented.");
    },
  };
};

export default amplifyDataProvider;
