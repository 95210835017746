import { Space } from "@pankod/refine-antd";
import { FC } from "react";

interface IStatsProps {
  data?: any
}

const Stats:FC<IStatsProps> = ({data}) => (


  <Space direction="vertical">
    <Space size="middle">
      <div
        style={{ padding: "8px", borderRadius: 5, backgroundColor: "#E4F2FE" }}
      >
        <img
          src={"/images/icons/patient.png"}
          alt=""
          style={{
            width: "28px",
            // height: "48px",
            //  padding: '12px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        />
      </div>
      <span>Number of Patients</span>
      
        <Space style={{marginLeft: 28}}>
            <strong style={{fontSize: 20}}>{data.patients}</strong>
            <span style={{color:"#56CA00"}}>+5 new this month</span>
        </Space>
    
    </Space>
    <Space size="middle">
      <div
        style={{ padding: "8px", borderRadius: 8, backgroundColor: "#DFFFE7" }}
      >
        <img
          src={"/images/icons/doctor-male.png"}
          alt=""
          style={{
            width: "24px",
            // height: "48px",
            //  padding: '12px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        />
      </div>
      <span>Number of Doctors</span>
      {/* <Space style={{marginLeft: 28}}>
            <strong style={{fontSize: 20}}>3</strong>
            <span style={{color:"#56CA00"}}>+0 new this month</span>
        </Space> */}
    </Space>



    <Space size="middle">
      <div
        style={{ padding: "8px", borderRadius: 8, backgroundColor: "#F2EBFF" }}
      >
        <img
          src={"/images/icons/nurse.png"}
          alt=""
          style={{
            width: "24px",
            // height: "48px",
            //  padding: '12px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        />
      </div>
      <span>Number of Nurses</span>
      {/* <Space style={{marginLeft: 28}}>
            <strong style={{fontSize: 20}}>1</strong>
            <span style={{color:"#56CA00"}}>+0 new this month</span>
        </Space> */}
    </Space>

    <Space size="middle">
      <div
        style={{ padding: "8px", borderRadius: 8, backgroundColor: "#FCFFCD" }}
      >
        <img
          src={"/images/icons/stethoscope.png"}
          alt=""
          style={{
            width: "24px",
            // height: "48px",
            //  padding: '12px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        />
      </div>
      <span>Number of Personnel</span>
      {/* <Space style={{marginLeft: 36}}>
            <strong style={{fontSize: 20}}>1</strong>
            <span style={{color:"#56CA00"}}>+0 new this month</span>
        </Space> */}
    </Space>

    <Space size="middle">
      <div
        style={{ padding: "8px", borderRadius: 8, backgroundColor: "#FDEDE1" }}
      >
        <img
          src={"/images/icons/new-message.png"}
          alt=""
          style={{
            width: "24px",
            // height: "48px",
            //  padding: '12px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        />
      </div>
      <span>Number of Messages</span>
      <Space style={{marginLeft: 20}}>
            <strong style={{fontSize: 20}}>{data?.messages}</strong>
            <span style={{color:"#56CA00"}}>+8 new this month</span>
        </Space>
    </Space>
  </Space>
);

export default Stats;
