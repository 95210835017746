import React from "react";
import surveyCategories from "assets/data/surveyCategories.json";

// We have four categories 1 - green, 2 - orange, 3 - red, 0 - Not applicable

interface ISurveyAnswer {
  questionId: string;
  grade: number;
}

const getSingleGrade = (questionId: string, grade: number): number => {
  if (surveyCategories[0].twoAnswers.includes(questionId)) {
    switch (grade) {
      case 1:
        return 3;
      case 2:
        return 1;
      case 3:
        return 0;
      default:
        return 0;
    }
  } else {
    switch (grade) {
      case 1:
        return 1;
      case 2:
        return 1;
      case 3:
        return 2;
      case 4:
        return 3;
      case 5:
        return 3;
      case 6:
        return 0;
      default:
        return 0;
    }
  }
};

export const getAverageGrade = (survey: ISurveyAnswer[]): number => {
  let skipAnswer = 0;

  const total = survey.reduce((prev, curr) => {
    const singleGrade = getSingleGrade(curr.questionId, curr.grade);
    if (singleGrade === 0) skipAnswer++;

    return prev + singleGrade;
  }, 0);

  return Math.round(total / (survey.length - skipAnswer));
};

export const getColorCode = (
  grade: number | undefined,
  isGoing: boolean = false
): React.ReactNode => {
  let color = "#c0c4c4";

  switch (grade) {
    case 0:
      break;
    case 1:
      color = "#6bcb77";
      break;
    case 2:
      color = "#ffae6b";
      break;
    case 3:
      color = "#ff6b6b";
      break;
    default:
      break;
  }

  return (
    <div
      className={isGoing ? "survey-circle-pulsing" : "survey-circle"}
      style={{
        backgroundColor: color,
      }}
    ></div>
  );
};

const getSingleColorCode = (
  questionId: string,
  grade: number,
  isGoing: boolean = false
): React.ReactNode => getColorCode(getSingleGrade(questionId, grade), isGoing);

const getAverageColorCode = (survey: ISurveyAnswer[]): React.ReactNode =>
  getColorCode(getAverageGrade(survey));

export { getSingleColorCode, getAverageColorCode };
