import { Row, Col } from '@pankod/refine-antd';

import ConditionalSelect from 'components/ConditionalSelect/ConditionalSelect';
import patientData from 'assets/data/patientData.json';

import './FamilyHistory.less';

interface Props {
  value?: any;
  onChange?: any;
}

const CLASS = 'hurone-family-history';

const layout = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 };

const FamilyHistory: React.FC<Props> = ({ value, onChange }) => {
  const handleUpdateForm = (selectedValues: number[], dataKey: any) => {
    const updatedForm = { ...value, [dataKey]: selectedValues };
    onChange(updatedForm);
  };

  const handleRemoveKey = (dataKey: any) => {
    const updatedForm = { ...value };
    delete updatedForm[dataKey];
    onChange(updatedForm);
  };

  return (
    <div className={CLASS}>
      {patientData.familyHistory.map((option, index, array) => {
        if (index % 2 !== 0) {
          const previousOption = array[index - 1];
          return (
            <Row gutter={20} key={index}>
              <Col {...layout}>
                <ConditionalSelect
                  value={value?.[previousOption?.value]}
                  onChange={handleUpdateForm}
                  onUncheck={handleRemoveKey}
                  label={previousOption.label}
                  options={previousOption.siblings}
                  dataKey={previousOption.value}
                  key={previousOption.value}
                />
              </Col>
              <Col {...layout}>
                <ConditionalSelect
                  value={value?.[option.value]}
                  onChange={handleUpdateForm}
                  onUncheck={handleRemoveKey}
                  label={option.label}
                  options={option.siblings}
                  dataKey={option.value}
                  key={option.value}
                />
              </Col>
            </Row>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default FamilyHistory;
