import dayjs from 'dayjs';
import addZeroPrefixToNumber from 'utils/addZeroPrefixToNumber';

const getYears = (disableFutureDates: boolean): string[] => {
  const maxYear = disableFutureDates ? dayjs().year() : dayjs().year() + 2;
  const years = [];

  for (let i = maxYear; i > 1900; i--) {
    years.push(i.toString());
  }

  return years;
};

const getMonths = (date: string, disableFutureDates: boolean, year:any): string[] => {
  let monthNames: string[] = dayjs.monthsShort();

  const selectedYear = year//dayjs(date).year();
  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();

  if (disableFutureDates && selectedYear === currentYear) {
    console.log({selectedYear})
    monthNames = monthNames.slice(0, currentMonth + 1);
  }

  return monthNames;
};

const getDays = (date: string, disableFutureDates: boolean, year:any): string[] => {
  let days = [];
  const numOfDays = dayjs(date).daysInMonth();

  const selectedYear = year//dayjs(date).year();
  const currentYear = dayjs().year();
  const selectedMonth = dayjs(date).month();
  const currentMonth = dayjs().month();
  const currentDay = dayjs().date();

  for (let i = 1; i <= numOfDays; i++) {
    const day = addZeroPrefixToNumber(i);
    days.push(day);
  }

  if (disableFutureDates && selectedYear === currentYear && selectedMonth === currentMonth) {
    days = days.slice(0, currentDay);
  }

  return days;
};

export { getYears, getMonths, getDays };