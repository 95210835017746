import React, { FC, useContext, useEffect, useState } from "react";

import { Form, Input, Select, Row, Col, Icons } from "@pankod/refine-antd";
import Selects from "components/selects";
import DateOfBirth from "components/birthDate";
import { PhoneNumber, validatePhoneNumber } from "components/phoneNumber";
import { UserDataContext } from "App";
import { listEditors } from "providers/adminQueriesProvider";
interface IBasicInformationProps {
  patientID?: string;
  isFullScreen?: boolean;
}

const { InfoCircleOutlined } = Icons;

export const BasicInformation: FC<IBasicInformationProps> = (props) => {
  const { patientID, isFullScreen } = props;
  const [patientHasCaregiver, setPatientHascareGiver] = useState(false);
  const user = useContext(UserDataContext);
  

  useEffect(() => {
    listEditors().then(res => console.log(res?.Users))
 
  },[])
  return (
    <>
      <Row gutter={16}>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Patient ID"
            name="patientId"
            rules={[
              {
                required: true,
                message: "Patient ID field is required.",
              },
              {
                pattern: /^[A-Za-z0-9]+$/,
                message:
                  "Patient ID field must contain only numbers and letters.",
              },
            ]}
          >
            <Input maxLength={13} />
          </Form.Item>
        </Col>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[
              {
                required: true,
                message: "Full Name field is required.",
              },
              {
                pattern: /^[a-zA-Z\s]*$/,
                message: "Full Name field must contains only letters",
              },
            ]}
          >
            <Input maxLength={50} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Date of Birth"
            name="dateOfBirth"
            rules={[
              {
                required: true,
                message: "Date of Birth field is required.",
              },
            ]}
          >
            <DateOfBirth disableFutureDates={false} decreaseYear={4} />
          </Form.Item>
        </Col>

        <Col span={isFullScreen ? 2 : 4}>
          <Form.Item dependencies={["firstMenstrualPeriod"]}>
            {({ getFieldValue, setFieldsValue }) => {
              const firstMenstrualPeriod = getFieldValue(
                "firstMenstrualPeriod"
              );
              return (
                <Form.Item
                  initialValue={0}
                  label="Gender"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Gender field is required.",
                    },
                  ]}
                >
                  <Selects
                    onChange={(gender) => {
                      if (gender === 1 && firstMenstrualPeriod)
                        setFieldsValue({ firstMenstrualPeriod: null });
                    }}
                    datakey="gender"
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={isFullScreen ? 4 : 8}>
          <Form.Item
            label="District"
            name="district"
            rules={[
              {
                required: true,
                message: "District field is required.",
              },
            ]}
          >
            <Selects datakey="district" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Village"
            name="village"
            rules={[
              {
                required: true,
                message: "Village field is required.",
              },
              {
                pattern: /^[a-zA-Z\s]*$/,
                message: "Village field must contains only letters",
              },
            ]}
          >
            <Input maxLength={36} />
          </Form.Item>
        </Col>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Address"
            name="address"
            rules={[
              {
                required: true,
                message: "Address field is required.",
              },
            ]}
          >
            <Input maxLength={60} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Phone number"
            name="phoneNumber"
            rules={[...validatePhoneNumber, { required: true }]}
            initialValue={{ dialCode: "+250", number: "" }}
          >
            <PhoneNumber />
          </Form.Item>
        </Col>

        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Religion"
            name="religion"
            rules={[
              {
                required: true,
                message: "Religion field is required.",
              },
            ]}
          >
            <Selects datakey="religion" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Level of Education"
            name="levelOfEducation"
            rules={[
              {
                required: true,
                message: "Level of Education field is required.",
              },
            ]}
          >
            <Selects datakey="levelOfEducation" />
          </Form.Item>
        </Col>

        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Employment Status"
            name="employmentStatus"
            rules={[
              {
                required: true,
                message: "Employment Status field is required.",
              },
            ]}
          >
            <Selects datakey="employmentStatus" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Language"
            name="language"
            initialValue={1}
            tooltip={{
              title: "Language in which the patient will receive messages",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select>
              <Select.Option value={1}>Kinyarwanda</Select.Option>
              <Select.Option value={2}>English</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={isFullScreen ? 6 : 12}>
          <Form.Item
            label="Patient has a Caregiver"
            name="hasCaregiver"
            initialValue={patientHasCaregiver}
          >
            <Select
              onChange={() => setPatientHascareGiver(!patientHasCaregiver)}
            >
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {patientHasCaregiver && (
        <Row gutter={16}>
          <Col span={isFullScreen ? 6 : 12}>
            <Form.Item
              label="Caregiver Full Name"
              name="caregiverFullName"
              rules={[
                { required: true },
                {
                  pattern: /^[a-zA-Z\s]*$/,
                  message:
                    "Caregiver Full Name field must contains only letters",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={isFullScreen ? 6 : 12}>
            <Form.Item
              label="Caregiver Phone number"
              name="caregiverPhoneNumber"
              rules={[...validatePhoneNumber, { required: true }]}
              initialValue={{ dialCode: "+250", number: "" }}
            >
              <PhoneNumber />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Form.Item label="" name="id" initialValue={patientID} hidden>
        <Input maxLength={50} />
      </Form.Item>

      <Form.Item label="" name="createdBy" initialValue={user?.username} hidden>
        <Input maxLength={50} />
      </Form.Item>
    </>
  );
};
