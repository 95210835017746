import { useState, useEffect, useRef } from "react";
import { Input, Select } from "@pankod/refine-antd";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";

import addZeroPrefixToNumber from "../../utils/addZeroPrefixToNumber";
import { getYears, getMonths, getDays } from "./getDateOptions";

import "./style.less";

dayjs.extend(localeData);

const { Option } = Select;

interface Props {
  value?: string;
  onChange?: any;
  disableFutureDates?: boolean;
  decreaseYear?: number;
}

const CLASS = "hurone-birth-date";

const DateOfBirth: React.FC<Props> = ({
  value,
  onChange,
  disableFutureDates = false,
  decreaseYear = 0,
}) => {
  const [date, setDate] = useState<string>(
    value ? dayjs(value).toISOString() : dayjs().toISOString()
  );
  const [day, setDay] = useState<number | null>(
    value ? dayjs(value).date() : null
  );
  const [month, setMonth] = useState<number | null>(
    value ? dayjs(value).month() : null
  );
  const [year, setYear] = useState<number | null>(
    value ? dayjs(value).year() : null
  );

  const dayRef = useRef<any>();
  const monthRef = useRef<any>();
  const yearRef = useRef<any>();

  useEffect(() => {
    if (day && year && month?.toString()) {
      onChange(date);
    }
  }, [day, month, year]);

  const focusNextInput = (currentInput: string) => {
    if (currentInput === "date" && !day) {
      monthRef.current.focus();
    } else if (currentInput === "month" && !year) {
      yearRef.current.focus();
    } else if (currentInput === "year" && !year) {
      yearRef.current.blur();
    }
  };

  const handleSelectDate = (val: number, type: any) => {
    let updatedDate = dayjs(date).set(type, val);

    // Get day from dayjs in case that it does not exists in selected month
    const day = dayjs(updatedDate).date();

    switch (type) {
      case "date":
        setDay(day);
        break;
      case "month":
        setMonth(val);
        break;
      case "year":
        setYear(val);
        break;
      default:
        break;
    }
    setDate(updatedDate.toISOString());

    focusNextInput(type);
  };

  return (
    <Input.Group compact>
      <Select
        className={`${CLASS}_select`}
        value={day}
        onChange={(val) => handleSelectDate(val, "date")}
        filterOption={(input, option: any) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option?.value.toString().indexOf(input.toLowerCase()) >= 0
        }
        placeholder="Day"
        showSearch
        style={{ width: "33%" }}
        ref={dayRef}
      >
        {getDays(date, disableFutureDates, year).map((day: string, index: number) => (
          <Option key={index} value={index + 1}>
            {day}
          </Option>
        ))}
      </Select>
      <Select
        className={`${CLASS}_select`}
        value={month}
        onChange={(val) => handleSelectDate(val, "month")}
        filterOption={(input, option: any) =>
          option?.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          addZeroPrefixToNumber(option?.value + 1).indexOf(
            input.toLowerCase()
          ) >= 0
        }
        placeholder="Month"
        showSearch
        style={{ width: "34%" }}
        ref={monthRef}
      >
        {getMonths(date, disableFutureDates, year).map(
          (month: string, index: number) => (
            <Option key={index} value={index}>
              {month}
            </Option>
          )
        )}
      </Select>

      <Select
        className={`${CLASS}_select`}
        value={year}
        onChange={(val) => handleSelectDate(val, "year")}
        placeholder="Year"
        showSearch
        style={{ width: "33%" }}
        ref={yearRef}
      >
        {getYears(disableFutureDates).map((year, index) => (
          <Option key={index} value={Number(year) - decreaseYear}>
            {Number(year) - decreaseYear}
          </Option>
        ))}
      </Select>
    </Input.Group>
  );
};

export default DateOfBirth;
