import { Tag, Tooltip } from '@pankod/refine-antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

import './tagRender.less';

const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
  event.preventDefault();
  event.stopPropagation();
};

const tagRender = (props: CustomTagProps) => {
  const { label, closable, onClose } = props;

  return (
    <Tag
      className="hurone-tag"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {label}
    </Tag>
  );
};

const generateTooltipList = (omittedValues: any, listLimit: number) => {
  const ommitedItemsList = [];

  for (let i = 0; i < omittedValues.length; i++) {
    if (i < listLimit) {
      ommitedItemsList.push(<li key={i}>{omittedValues[i].label}</li>);
    } else {
      const itemsLeft = omittedValues.length - i;
      ommitedItemsList.push(<li key={i}>{`and ${itemsLeft} more`}</li>);
      break;
    }
  }

  return ommitedItemsList;
};

const maxTagPlaceholder = (omittedValues: any) => {
  return (
    <Tooltip
      title={<ul className="hurone-tag-tooltip">{generateTooltipList(omittedValues, 5)}</ul>}
    >
      {<Tag className="hurone-tag">{`+${omittedValues.length}`}</Tag>}
    </Tooltip>
  );
};

export { tagRender, maxTagPlaceholder };
