import {
  useTable,
  List,
  Table,
  Button,
  Icons,
  DateField,
} from "@pankod/refine-antd";
import { useList, useOne } from "@pankod/refine-core";
import CardItem from "components/cardItem";
import { toFullName } from "utils/usernameTransform";
import dayjs from "dayjs";

const { PlusOutlined } = Icons;

export const AppointmentsTabPane = (props: any) => {
  const {
    patientID,
    title,
    addNewButton,
    pagination,
    initialPageSize,
    onAddNew,
  } = props;

  const appointmentsQuery: any = useList({
    resource: "appointments",
    config: {
      filters: [
        {
          field: "patientID",
          operator: "eq",
          value: patientID,
        },
      ],
    },
    queryOptions: {
      enabled: patientID ? true : false,
    },
  });

  return (
    <List
      breadcrumb={false}
      headerProps={{
        title: title !== false ? "Appointments History" : null,
        extra:
          addNewButton !== false ? (
            <Button type="primary" icon={<PlusOutlined />} onClick={onAddNew}>
              New Appointment
            </Button>
          ) : null,
      }}
    >
      <Table
        dataSource={
          initialPageSize
            ? appointmentsQuery?.data?.data.slice(0, initialPageSize)
            : appointmentsQuery?.data?.data
        }
        // {...tableProps}
        rowKey="id"
        pagination={pagination ?? true}
        loading={appointmentsQuery?.data?.isLoading}
      >
        <Table.Column
          dataIndex="appointmentDate"
          title="Date"
          render={(val) => <DateField value={val} format="DD/MM/YYYY" />}
          defaultSortOrder="descend"
          sorter={(a: any, b: any) =>
            dayjs(a?.createdAt).unix() - dayjs(b?.createdAt).unix()
          }
        />
        <Table.Column
          dataIndex="appointmentLocation"
          title="Location"
          render={(val) => <span>{val}</span>}
        />
        <Table.Column
          dataIndex="doctor"
          title="Doctor"
          render={(val) => <span>{toFullName(val)}</span>}
        />
        <Table.Column
          dataIndex="appointmentReason"
          title="Reason"
          render={(val) => <span>{val}</span>}
        />
        <Table.Column
          dataIndex="treatmentStatus"
          title="Treatment Status"
          render={(val) => <CardItem datakey="treatmentStatus" valkey={val} />}
        />
      </Table>
    </List>
  );
};
